import { apiCall } from "../../utilities/apiCall";
import {
  encryptByDES,
  authEncryptDecryptKey,
} from "../../utilities/commonHelpers";
import {
  API_ADMIN_REQUIREDFIELDS_BYPAGENAME,
  API_MASTER_DATA,
  API_CLIENT_SETTINGS_GET,
  API_PRODUCTION_PROPOSAL_HISTORY_SAVE,
  API_TEMPLATES_PROPOSAL_PRIVIEW,
  API_TEMPLATES_CHANGEORDER_PREVIEW,
} from "../../utilities/apiUrls";
import { resetSession } from "../../utilities/sessionHelpers";
import { cpSession } from "../../components/CustomerPortal/helpers/cpSession";

//api urls
export const CustomerAuthenticationURL =
  "/services/Authentication/Customer/Data";
export const GetCustomerTokenDetailsURL =
  "/services/Authentication/Customer/Data/ByCode";
export const GetContactDetailsURL = "/services/Crm/Contacts/";
export const GetAddressListURL = "/services/crm/contacts/Address/";

export const GetSubscriptionListURL = "/services/Subscriptions/bycustomer/";
export const API_SUBSCRIPTION_CANCEL = "/services/Subscriptions/Cancel/";

export const GetSavedCardDetailsURL = "/services/ar/paymentgateways/card/";
export const GetSubContactDetailsURL = "/services/Crm/Contacts/SubContacts/";
export const GetCompanyDefaultsURL = "/services/Admin/CompanyDefaults/cp";

export const GetContactsListURL = "/services/Crm/Contacts/Criteria";
export const GetCustomerOpenInvoicesURL =
  "/services/AR/Invoices/Customer/OpenInvocies/";
export const GetCustomerOrdersURL = "/services/production/orders/bycustomer/";
export const GetOrdersDeleteURL = "/services/production/ordersdelete/";
export const GetPaymentPlanInstallmentsURL =
  "/services/PaymentPlan/Installments/Customer/";

export const GetJobJacketAddURL =
  "/services/production/jobjacket/addproductionnote";
export const GetJobJacketAddNotesURL = "/services/production/jobjacket/notes/";
export const CRMSERVICE_CUSTOMERPRODNOTES = "/CustomerProdNotes";

export const ImageUploadURL = "/services/file/create";
export const GetImageURL = "/services/file/fileget";
export const GetInvoiceHtmlURL = "/services/AR/invoices/preview/";
export const GetInstallmentHtmlURL = "/services/PaymentPlan/preview/";
export const API_CRM_CONTACTS_UPDATE = "/services/Crm/Contacts/Update";

export const API_GET_BILLING_HISTORY =
  "/services/AR/Payments/InvoiceAndInstallment/";

export const API_ADMIN_CUSTOMERPORTAL_SECTIONS_GET =
  "/services/Admin/CustomerPortal/Sections/";
export const API_ADMIN_CUSTOMERPORTAL_SECTIONS_SAVE =
  "/services/Admin/CustomerPortal/Sections/";
export const API_GET_USERS_APIS_LIST_DATA = "/services/Authentication/APIUser";

export const CustomerLoginURL = "/CustomerLogin";
export const CustomerListURL = "/Customer/CustomerList";
export const CustomerHomeURL = "/Customer/CustomerHome";
export const CustomerAuthenticationApisUrl =
  "/Customer/CustomerAuthenticationUrls";

export const cpServices = () => {
  //to authenticate log in
  function customerAuthentication(logOnModel) {
    const userDetailsDTO = {
      UserName: encryptByDES(logOnModel.emailID, authEncryptDecryptKey),
      Password: encryptByDES(logOnModel.password, authEncryptDecryptKey),
      ClientSiteURL: logOnModel.ClientSiteURL,
      Source: "cp",
    };

    return new Promise((resolve, reject) => {
      const apiURLCustomerAuthentication = `${CustomerAuthenticationURL}`;
      apiCall(apiURLCustomerAuthentication, "POST", userDetailsDTO)
        .then((resp) => {
          if (
            resp.hasOwnProperty("content") &&
            resp.content.Status === "Success"
          ) {
            resolve(resp.content.Data);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  // Token Login
  function GetCustomerTokenDetails(encryptedString) {
    return new Promise((resolve, reject) => {
      apiCall(`${GetCustomerTokenDetailsURL}`, "POST", encryptedString)
        .then((resp) => {
          if (
            resp.hasOwnProperty("content") &&
            resp.content.Status === "Success"
          ) {
            resolve(resp.content.Data);
          } else if (
            resp.hasOwnProperty("content") &&
            resp.content.Status === "BadRequest"
          ) {
            reject(resp.content.Messages);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  //Note : clientID is for token generation
  //to get parent id
  function getContactDetails(customerId, parentId, clientID) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        resetSession();
        window.location.reload();
      } else {
        const apiURLtoGetContactDetails = `${GetContactDetailsURL}${customerId}/Name,ID,SalesRepID,FirstName,LastName,JobDescription,Prefix,Suffix,Email,Phone,PhoneExt,ParentID,IsAdAgency,LastContractStatus,InActive`;
        apiCall(apiURLtoGetContactDetails, "GET")
          .then((resp) => {
            if (resp.content.Status === "Success") {
              resolve(JSON.parse(resp.content.JSONContent));
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }
  function updateBasicField(FieldDTO) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLUpdateBasicField = `${API_CRM_CONTACTS_UPDATE}`;
        apiCall(apiURLUpdateBasicField, "POST", FieldDTO)
          .then((resp) => {
            if (resp.content) {
              resolve(resp.content);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  function getUrlsUsersList(email) {
    return new Promise((resolve, reject) => {
      const apiURLsUsersList = `${API_GET_USERS_APIS_LIST_DATA}/${email}`;

      apiCall(apiURLsUsersList, "GET")
        .then((resp) => {
          if (resp.content.Status === "Success") {
            resolve(resp.content.List);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  function updateAddressField(FieldDTO) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        apiCall(`${GetAddressListURL}`, "POST", FieldDTO)
          .then((resp) => {
            if (resp.content.Status === "Success") {
              resolve(resp.content);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }
  function getSavedCardDetails(contactID) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLtoGetSavedCards = `${GetSavedCardDetailsURL}${contactID}`;
        apiCall(apiURLtoGetSavedCards, "GET")
          .then((resp) => {
            if (resp.content) {
              resolve(resp.content);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  function getContactsList(customersIDs) {
    const customerListDTO = {};
    customerListDTO.CustomerIDs = customersIDs;
    customerListDTO.RequiredFields =
      "Name,ID,gsCustomersID,CustomerImageUrl,JobDescription,FirstName,LastName,Prefix,Suffix,Email,Phone,PhoneExt,LastContractStatus,ParentID,IsAdAgency,IsInvoiceExists";
    customerListDTO.EmailIDs = "";
    customerListDTO.Action = "BASIC_BULK";

    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLtoGetContactsList = `${GetContactsListURL}`;
        apiCall(apiURLtoGetContactsList, "POST", customerListDTO)
          .then((resp) => {
            if (resp.content.Status === "Success") {
              resolve(JSON.parse(resp.content.JSONContent));
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  //Note : clientID is for token generation
  function getSubContactsList(parentID, clientID) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLtoGetSubContactsList = `${GetSubContactDetailsURL}${parentID}`;
        apiCall(apiURLtoGetSubContactsList, "GET")
          .then((resp) => {
            if (resp.content.Status === "Success") {
              const responseData =
                resp.content.List === null ? [] : resp.content.List;
              resolve(responseData);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  //Note : clientID is for token generation
  function getClientSettings(clientID) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLtoGetClientSettings = `${API_CLIENT_SETTINGS_GET}${clientID}/false/DateFormat,CurrencySymbol,CPCompanyName,mm_address [ClientAddress],CustomerPortalLogo,CultureInfo,CultureUI`;

        apiCall(apiURLtoGetClientSettings, "GET")
          .then((resp) => {
            if (resp.content.Status === "Success") {
              const responseData =
                resp.content.Data === null ? {} : resp.content.Data;
              apiCall(`${GetCompanyDefaultsURL}`, "GET").then((resp) => {
                const responseCP = resp.content === null ? {} : resp.content;
                responseData.CustomerPortalDetails = responseCP;
                resolve(responseData);
              });
            } else {
              reject(resp.content.Messages[0].Message);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  //Note : clientID is for token generation
  function getCustomerOrdersTasks(
    startIndex,
    pageSize,
    customerId,
    fromDate,
    toDate,
    page,
    productType
  ) {
    // let querystring = "";
    // if (fromDate !== "") {
    //   querystring = `?fromDate=${fromDate}`;
    //   if (toDate !== "") {
    //     querystring = querystring + `&toDate=${toDate}`;
    //   }
    // }
    // querystring = querystring + `&page=${page}`;
    // querystring = querystring + `&productType=${productType}`;
    const body = {
      CustomerID:customerId,
      Year:1000,
      IsColumnConfigNeeded:false,
      StartIndex:startIndex,
      Limit: pageSize,
      UserID:0,
      FromDate:fromDate?fromDate:null,
      ToDate:toDate?toDate:null,
      Page:page,
      ProductType:productType,
    };
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
     //   const apiURLGetCustomerOrders = `${GetCustomerOrdersURL}${customerId}/1000/false/${startIndex}/${pageSize}/0${querystring}`;
        apiCall(GetCustomerOrdersURL, "POST",body)
          .then((resp) => {
            if (resp.content.Status === "Success") {
              resolve(resp.content.Data);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  //Note : clientID is for token generation
  //this is for new record
  function getCustomerLogo(imageName, clientId, FileUploadSource) {
    const fileObjectDTO = {};
    fileObjectDTO.ClientID = clientId;
    fileObjectDTO.FolderPath = imageName;
    fileObjectDTO.FileUploadSource = FileUploadSource;

    return new Promise((resolve, reject) => {
      const apiURLGetCustomerLogo = `${GetImageURL}`;
      apiCall(apiURLGetCustomerLogo, "POST", fileObjectDTO)
        .then((resp) => {
          if (resp.content && resp.content !== null) {
            const responseData = resp.content === null ? {} : resp.content;
            resolve(responseData);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  //Note : clientID is for token generation
  function getCustomerOpenInvoices(customerId, clientId) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        resetSession();
        window.location.reload();
      } else {
        const apiURLGetCustomerOpenInvoices = `${GetCustomerOpenInvoicesURL}${customerId}/true/MCP`;
        apiCall(apiURLGetCustomerOpenInvoices, "GET")
          .then((response) => {
            if (response.content.Status === "Success") {
              const responseData =
                response.content.Data.OpenInvoices === null
                  ? []
                  : response.content.Data.OpenInvoices;
              resolve(responseData);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  //Note : clientID is for token generation
  function getOpenInvoicesOrPaymentPlanInstallments(
    customerId,
    isPPInstallment
  ) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLGetPaymentPlanInstallments = isPPInstallment
          ? `${GetPaymentPlanInstallmentsURL}${customerId}/false/MCP`
          : `${GetCustomerOpenInvoicesURL}${customerId}/true/MCP`;
        apiCall(apiURLGetPaymentPlanInstallments, "GET")
          .then((resp) => {
            if (resp.content.Status === "Success") {
              const responseData = isPPInstallment
                ? resp.content.List === null
                  ? []
                  : resp.content.List
                : resp.content.Data.OpenInvoices === null
                ? []
                : resp.content.Data.OpenInvoices;
              resolve(responseData);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  //TO DO:need to get session data & new/update production notes
  function customerProdNotes(
    contractId,
    operation,
    notes,
    imageName,
    isNew,
    salesRepID,
    clientID,
    crmServiceUrl,
    taskId = -1
  ) {
    //session manager :isNew,salesRepID
    const fileObjectDTO = {};
    fileObjectDTO.ClientID = clientID;
    fileObjectDTO.FolderPath = imageName;
    // fileObjectDTO.FileUploadSource = EnumFileUploadSource.PublisherLogo;
    if (!cpSession().IsAuthenticated()) {
      window.location.reload();
      resetSession();
    } else if (operation === "updated") {
      if (isNew) {
        var dict = { ID: salesRepID.ToString() };
        // headers = Hedears(TokenGenerator(clientId.ToString(), SessionManager.Email), string.Empty);
        const postDataObject = {};
        postDataObject.Add("OrderID", contractId.toString());
        postDataObject.Add("Note", notes);
        postDataObject.Add("Internal", "false");
        postDataObject.Add("Creator", dict);
        postDataObject.Add("FilePath", imageName);
        postDataObject.Add("Final", "false");
        postDataObject.Add("IsCustomerPortal", "true");
        postDataObject.Add("TaskID", taskId.toString());

        return new Promise((resolve, reject) => {
          // mServiceUrl = $"{WebApIUrl}{GetJobJacketAddURL}";
          const apiURLGetCustomerOrders = `${GetJobJacketAddURL}`;
          apiCall(apiURLGetCustomerOrders, "POST", postDataObject)
            .then((resp) => {
              if (resp.content.Status === "Success") {
                const responseData =
                  resp.content.List === null ? [] : resp.content.List;
                resolve(responseData);
              } else {
                reject("fail");
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        // TODO :PostData
        // mServiceUrl = string.Format(CultureInfo.InvariantCulture, "{0}/{1}?clientid={2}&contractid={3}&operation={4}&imagename={5}&noteSource={6}", CrmServiceUrl, WebUtilities.CRMSERVICE_CUSTOMERPRODNOTES, clientId, contractId, operation, imageName, "Mirabel's Customer Portal");
        const mServiceUrl = `${CRMSERVICE_CUSTOMERPRODNOTES}?clientid=${clientID}&contractid=${contractId}&operation=update&imagename=${imageName}&noteSource=Mirabel's Customer Portal`;
        return new Promise((resolve, reject) => {
          // mServiceUrl = $"{WebApIUrl}{GetCustomerOrdersURL}{customerId}/1000/false/{startIndex}/{PageSize}/0";
          apiCall(
            mServiceUrl,
            "POST",
            notes,
            {},
            "no-cache",
            "cors",
            {},
            false,
            crmServiceUrl
          )
            .then((resp) => {
              if (resp.content.Status === "Success") {
                const responseData =
                  resp.content.List === null ? [] : resp.content.List;
                resolve(responseData);
              } else {
                reject("fail");
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      }
    } else {
      return new Promise((resolve, reject) => {
        // mServiceUrl = $"{WebApIUrl}{GetJobJacketAddNotesURL}{contractId}";
        const apiURLGetJobJacketAddNotes = `${GetJobJacketAddNotesURL}${contractId}/${taskId}`;
        apiCall(apiURLGetJobJacketAddNotes, "GET")
          .then((resp) => {
            if (resp.content.Status === "Success") {
              const responseData =
                resp.content.List === null ? [] : resp.content.List;
              resolve(responseData);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    }
  }

  //this is for new record
  //TO DO:  private static string ImageUploadWithFileServer(string name, System.IO.Stream imageInputStream, int clientId)
  function imageUploadNew(fileObjectDTO) {
    return new Promise((resolve, reject) => {
      const apiURLImageUpload = `${ImageUploadURL}`;
      apiCall(apiURLImageUpload, "POST", fileObjectDTO)
        .then((resp) => {
          if (resp.content) {
            resolve(resp.content);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  function getInvoiceOrInstallmentHTML(invoiceID, isPPInstallment) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        const apiURLGetInvoiceHtml = isPPInstallment
          ? `${GetInstallmentHtmlURL}${invoiceID}`
          : `${GetInvoiceHtmlURL}${invoiceID}/true`;
        apiCall(apiURLGetInvoiceHtml, "GET")
          .then((resp) => {
            if (resp.content.Status === "Success") {
              const responseData = resp.content === null ? [] : resp.content;
              resolve(responseData);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  function getCustomerPortalSettings(clientid) {
    return new Promise((resolve, reject) => {
      const apiURLGetCustomerPortalSettings = `${API_ADMIN_CUSTOMERPORTAL_SECTIONS_GET}${clientid}`;
      apiCall(apiURLGetCustomerPortalSettings, "GET")
        .then((resp) => {
          if (resp.content.Status === "Success") {
            const responseData =
              resp.content.Data === null ? {} : resp.content.Data;
            resolve(responseData);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function saveCustomerPortalSettings(objDTO) {
    return new Promise((resolve, reject) => {
      const apiURLSaveCustomerPortalSettings = `${API_ADMIN_CUSTOMERPORTAL_SECTIONS_SAVE}`;
      apiCall(apiURLSaveCustomerPortalSettings, "POST", objDTO)
        .then((resp) => {
          if (resp.content.Status === "Success") {
            resolve(1);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function getRequiredFieldsSetting() {
    return new Promise((resolve, reject) => {
      apiCall(API_ADMIN_REQUIREDFIELDS_BYPAGENAME)
        .then((resp) => {
          if (
            resp.content &&
            resp.content.List &&
            resp.content.List.length > 0
          ) {
            if (resp.content.Status === "Success") {
              const responseData = resp.content ? resp.content.List : {};
              resolve(responseData);
            } else {
              reject("fail");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function getAddressList(contactid) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        apiCall(`${GetAddressListURL}${contactid}`)
          .then((addressData) => {
            if (
              addressData &&
              addressData.content &&
              addressData.content.Status === "Success"
            ) {
              const responseData = addressData.content.List;
              resolve(responseData);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }
  function GetSubscriptions(contactid, status) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        apiCall(`${GetSubscriptionListURL}${contactid}/${status}`).then(
          (SubscriptionData) => {
            if (
              SubscriptionData &&
              SubscriptionData.content &&
              SubscriptionData.content.Status === "Success"
            ) {
              const responseData = SubscriptionData.content.List;
              resolve(responseData);
            } else {
              reject("fail");
            }
          }
        );
      }
    });
  }
  function CancelSubscripton(cancelDTO, subscriptionid) {
    return new Promise((resolve, reject) => {
      apiCall(
        `${API_SUBSCRIPTION_CANCEL}${subscriptionid}`,
        "POST",
        cancelDTO
      ).then((SubscriptionData) => {
        if (
          SubscriptionData &&
          SubscriptionData.content &&
          SubscriptionData.content.Status === "Success"
        ) {
          const responseData = SubscriptionData.content;
          resolve(responseData);
        } else {
          reject("fail");
        }
      });
    });
  }
  function CancelReasons() {
    return new Promise((resolve, reject) => {
      apiCall(`${API_MASTER_DATA}CancelReason`).then((CancelReasons) => {
        if (
          CancelReasons &&
          CancelReasons.content &&
          CancelReasons.content.Status === "Success"
        ) {
          const responseData = CancelReasons.content.Data;
          resolve(responseData);
        } else {
          reject("fail");
        }
      });
    });
  }
  function getBillingHistory(
    customerID,
    fromDate,
    toDate,
    currentPageIndex,
    pageSize = process.env.REACT_APP_PAGE_SIZE
  ) {
    const startIndex = (currentPageIndex - 1) * pageSize + 1;
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        apiCall(
          `${API_GET_BILLING_HISTORY}${customerID}/${fromDate}/${toDate}/${startIndex}/${pageSize}`
        )
          .then((resp) => {
            resolve(resp.content);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }

  function deleteOrder(deleteOrderPostObj) {
    return new Promise((resolve, reject) => {
      apiCall(`${GetOrdersDeleteURL}`, "POST", deleteOrderPostObj)
        .then((resp) => {
          if (resp.content && resp.content.Status === "Success") {
            resolve(resp);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function loadAmountBalance(gsCustomersID) {
    return new Promise((resolve, reject) => {
      if (!cpSession().IsAuthenticated()) {
        window.location.reload();
        resetSession();
      } else {
        apiCall(
          `/services/AR/Invoices/Customer/AmountBalance/`,
          "POST",
          gsCustomersID
        )
          .then((res) => {
            if (res && res.content) {
              resolve(res.content);
            } else {
              reject("fail");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  }
  function getProposalsToApprove(gsCustomersID, SalesContactID) {
    return new Promise((resolve, reject) => {
      apiCall(
        `/services/production/proposals/activeproposals/${gsCustomersID}/${SalesContactID}`
      )
        .then((res) => {
          if (res && res.content && res.content.Status === "Success") {
            resolve(res.content.Data);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function SaveProposalStatus(proposalData) {
    return apiCall(
      "/Services/production/proposals/viewstatus",
      "POST",
      proposalData
    );
  }
  function SaveProposalHistory(proposalStatusData) {
    return new Promise((resolve, reject) => {
      apiCall(API_PRODUCTION_PROPOSAL_HISTORY_SAVE, "POST", proposalStatusData)
        .then((res) => {
          if (res && res.content && res.content.Status === "Success") {
            resolve(res);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function getProposalApprovalForm(proposalID, isHideDetails = false) {
    return new Promise((resolve, reject) => {
      apiCall(
        `${API_TEMPLATES_PROPOSAL_PRIVIEW}${proposalID}/${isHideDetails}/false`
      )
        .then((res) => {
          if (res && res.content && res.content.Status === "Success") {
            resolve(res.content.Data);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function getChangeOrderTemplateDetails(changeOrderId, isHideDetails = false) {
    return new Promise((resolve, reject) => {
      apiCall(
        `${API_TEMPLATES_CHANGEORDER_PREVIEW}${changeOrderId}/${isHideDetails}`
      )
        .then((res) => {
          if (res && res.content && res.content.Status === "Success") {
            resolve(res.content.Data);
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  function getProposalStatus(CustomerId, proposalID) {
    return new Promise((resolve, reject) => {
      apiCall(`/Services/Production/proposals/${proposalID}/${CustomerId}`)
        .then((res) => {
          if (res && res.content && res.content.Status === "Success") {
            resolve({ ProposalData: res.content.Data.Proposals[0] });
          } else {
            reject("fail");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  return {
    encryptByDES,
    customerAuthentication,
    getContactDetails,
    getContactsList,
    getSubContactsList,
    updateBasicField,
    getClientSettings,
    getCustomerOrdersTasks,
    getCustomerLogo,
    getCustomerOpenInvoices,
    getOpenInvoicesOrPaymentPlanInstallments,
    customerProdNotes,
    imageUploadNew,
    updateAddressField,
    getInvoiceOrInstallmentHTML,
    getCustomerPortalSettings,
    GetSubscriptions,
    saveCustomerPortalSettings,
    getRequiredFieldsSetting,
    getAddressList,
    getSavedCardDetails,
    getBillingHistory,
    deleteOrder,
    loadAmountBalance,
    CancelSubscripton,
    CancelReasons,
    GetCustomerTokenDetails,
    getUrlsUsersList,
    getProposalsToApprove,
    getProposalApprovalForm,
    getChangeOrderTemplateDetails,
    getProposalStatus,
    SaveProposalStatus,
    SaveProposalHistory,
  };
};
